import React, { Component } from 'react'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import WpSvg from '../components/svgs/WpSvg'
import { configRedux } from '../redux/ConfigRedux'
import { getAbout, insertNewData } from '../actions/MainAction'
import InSvg from '../components/svgs/InSvg'
import InstSvg from '../components/svgs/InstSvg'
import FbSvg from '../components/svgs/FbSvg'
import PhoneSvg from '../components/svgs/PhoneSvg'
import FaqSection from '../components/sections/FaqSection'
import { mixinAlert } from '../actions/Alerts'
import { Helmet } from 'react-helmet'
export class ContactPage extends Component {
    state={
        first_name: '',
        last_name:'',
        email: '',
        topic:'',
        text: ''
    }
    componentDidMount(){
        this.props.dispatch(getAbout());
        window.scrollTo(0, 0);
    }

    sendRequest(data){
        if(data?.first_name != '' && data?.last_name != '' && data?.email != '' && data?.topic != '' && data.text != ''){
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            let url = 'contact';
            this.props.dispatch(insertNewData(url, formData))
            .then(resp=>{
                this.setState({btnDisable:false})

                this.setState({
                    first_name: '',
                    last_name:'',
                    email: '',
                    topic:'',
                    text: ''
                })
                if(resp == 'success'){
                    mixinAlert('success', "Müraciətiniz göndərildi.");
                }else{
                    mixinAlert('error', "Xəta başverdi.");
                }
            })
        }else{
            mixinAlert('error', "Zəhmət olmasa bütün xanaları doldurun.");
        }
    }
  render() {
    const {aboutData} = this.props;
    const {first_name, last_name, email, topic, text} = this.state;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bizimlə əlaqə - Nicode Academy</title>
          <meta name="description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta name="keywords" content="Nicode Academy, Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI " />
          <link rel="canonical" href="https://nicode.az/contact"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content="Bizimlə əlaqə - Nicode Academy"/>
          <meta property="og:title" content="Bizimlə əlaqə - Nicode Academy"/>
          <meta property="og:url" content="https://nicode.az/contact"/>
          <meta property="og:site_name" content="https://nicode.az/contact"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az"/>
          <meta property="og:title" content="Bizimlə əlaqə - Nicode Academy"/>
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
        </Helmet>
        <PageHeaderSection title={'Bizimlə əlaqə'} />
        <div className='grid_4-8 container mt-5'>
            <div className='contact_social_container'>
                <span className='contact_title'>Hesablarımız</span>
                <h3 className='contact_subtitle'>Əlaqə vasitələri</h3>
                <div className='contact_socials'>
                    <div className='social-list'>
                        <div className='social_icon'>
                            <WpSvg />
                        </div>
                        <div className='flex column'>
                            <span className='social_title'>WhatsApp</span>
                            <a className='social_url' href={`https://wa.me/${aboutData?.number}`}>Mesaj yaz!</a>
                        </div>
                    </div>
                    <div className='social-list'>
                        <div className='social_icon'>
                            <PhoneSvg />
                        </div>
                        <div className='flex column'>
                            <span className='social_title'>Əlaqə nömrəsi</span>
                            <a className='social_url' href={`tel:${aboutData?.number}`}>{aboutData?.number}</a>
                        </div>
                    </div>
                    
                    <div className='social-list'>
                        <div className='social_icon'>
                            <InstSvg />
                        </div>
                        <div className='flex column'>
                            <span className='social_title'>Instagram</span>
                            <a target='_blank' className='social_url' href={aboutData?.instagram}>Instagram</a>
                        </div>
                    </div>
                    <div className='social-list'>
                        <div className='social_icon'>
                            <FbSvg />
                        </div>
                        <div className='flex column'>
                            <span className='social_title'>Facebook</span>
                            <a target='_blank' className='social_url' href={aboutData?.facebook}>Facebook</a>
                        </div>
                    </div>
                    <div className='social-list'>
                        <div className='social_icon'>
                            <InSvg />
                        </div>
                        <div className='flex column'>
                            <span className='social_title'>Linkedin</span>
                            <a target='_blank' className='social_url' href={aboutData?.linkedin}>Linkedin</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact_input_container'>
                <h3 className='contact_inp_title'>Bizimlə əlaqə saxla</h3>
                <div className='contact_page-inp-cont'>
                    <input name={'first_name'} value={first_name} onChange={(e)=>this.setState({first_name: e.target.value})} placeholder='Ad' type='text' />
                    <input name={'last_name'} value={last_name} onChange={(e)=>this.setState({last_name: e.target.value})} placeholder='Soyad' type='text' />
                    <input name={'email'} value={email} onChange={(e)=>this.setState({email: e.target.value})} placeholder='E-poçt' type='email' />
                    <input name={'topic'} value={topic} onChange={(e)=>this.setState({topic: e.target.value})} placeholder='Mövzu' type='text' />

                </div>
                <textarea value={text} onChange={(e)=>this.setState({text: e.target.value})} className='contact_page_question_inp' placeholder='Sualınız'></textarea>
                <button onClick={this.sendRequest.bind(this, this.state)} className='contact_page_btn'>Mesaj göndər</button>
            </div>
        </div>
        <FaqSection />
      </div>
    )
  }
}

export default configRedux(ContactPage, ["aboutData"])