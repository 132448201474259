import React, { Component } from 'react'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import BlogCard from '../components/cards/BlogCard'
import { configRedux } from '../redux/ConfigRedux'
import { getBlogs } from '../actions/MainAction'
import { Helmet } from 'react-helmet'

export class BlogPage extends Component {
  componentDidMount(){
    this.props.dispatch(getBlogs());
    window.scrollTo(0, 0);
  }
  render() {
    const {blogs} = this.props;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bloqlarımız - Nicode Academy</title>
          <meta name="description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta name="keywords" content="Nicode Academy, Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI " />
          <link rel="canonical" href="https://nicode.az/blog"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content="Bloqlarımız - Nicode Academy"/>
          <meta property="og:title" content="Bloqlarımız - Nicode Academy"/>
          <meta property="og:url" content="https://nicode.az/blog"/>
          <meta property="og:site_name" content="https://nicode.az/blog"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az"/>
          <meta property="og:title" content="Bloqlarımız - Nicode Academy"/>
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
        </Helmet>
        <PageHeaderSection title={'Bloqlarımız'} />
        <section className='mt-5'>
          <div className='container flex column gap-20'>
            {
              blogs.map((data, i)=>{
                return(
                  <BlogCard 
                    key={i}
                    data={data}
                  />
                )
              })
            }
          </div>
        </section>
      </div>
    )
  }
}

export default configRedux(BlogPage, ["blogs"])