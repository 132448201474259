import React, { Component } from 'react'
import ContactSvg from '../svgs/ContactSvg'
import CloseSvg from '../svgs/CloseSvg';
import { connect } from 'react-redux';
import { insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/Alerts';
import SendSvg from '../svgs/SendSvg';

export class TrainingReturn extends Component {
    state={
        visible:false,
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        btnDisable:false
    }
    applyBtn(data){
        if(data?.first_name != '' && data?.last_name != '' && data?.email != '' && data?.phone_number != ''){
            this.setState({btnDisable:true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            formData.append('training_id', this.props.trainingId)
            let url = 'public-traning-apply';
            this.props.dispatch(insertNewData(url, formData))
            .then(resp=>{
                this.setState({btnDisable:false})

                this.setState({
                    first_name:'',
                    last_name:'',
                    email:'',
                    phone_number:'',
                    visible:false
                })
                if(resp == 'success'){
                    mixinAlert('success', "Müraciətiniz göndərildi.");
                }else{
                    mixinAlert('error', "Xəta başverdi.");
                }
            })
        }else{
            mixinAlert('error', "Zəhmət olmasa bütün xanaları doldurun.");
        }
    }
  render() {
    const {visible, first_name, last_name, email, phone_number, btnDisable} = this.state;
    return (
      <div className='training_return_cont'>
        {
            // visible?
            <div  style={{ height: visible? '435px': '0px', padding: visible? '20px':'0px' }}  className='training_return_inp_cont'>
                    <div className='grid-2-mini student_return'>
                        <div className='inp_container'>
                            <label htmlFor='firstName'>Ad</label>
                            <input value={first_name} onChange={(e)=>this.setState({first_name:e.target.value})} type='text'id='firstName' placeholder='Ad' />
                        </div>
                        <div className='inp_container'>
                            <label htmlFor='lastName'>Soyad</label>
                            <input  value={last_name} onChange={(e)=>this.setState({last_name:e.target.value})} type='text'id='lastName' placeholder='Soyad' />
                        </div>
                        <div className='inp_container'>
                            <label htmlFor='email'>E-poçt</label>
                            <input  value={email} onChange={(e)=>this.setState({email:e.target.value})} type='email'id='email' placeholder='E-poçt' />
                        </div>
                        <div className='inp_container'>
                            <label htmlFor='phone_number'>Əlaqə nömrəsi</label>
                            <input  value={phone_number} onChange={(e)=>this.setState({phone_number:e.target.value})} type='tel'id='phone_number' placeholder='Əlaqə nömrəsi' />
                        </div>
                        <button disabled={btnDisable} className='apply_button' onClick={this.applyBtn.bind(this, this.state)}>Göndər</button>
                    </div>
            </div>
        }
        
        <button onClick={()=>this.setState({visible: !visible})} className='training_return_cont_btn'>
            {/* {
                visible? <CloseSvg />: <ContactSvg />
            } */}
           <SendSvg /> Müraciət et
        </button>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({})

export default connect(mapStateToProps)(TrainingReturn)
