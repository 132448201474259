import moment from "moment/moment";

export const initialState = {
   loader: false,
   whyUs: [],
   aboutData:{
      title: '',
      description: '',
      number: '',
      instagram: '',
      facebook: '',
      linkedin: null,
      youtube: '',
      first_page_sub_title: '',
      first_page_title: '',
      first_page_text:''
   },
   trainings:[],
   faqs:[],
   studentReturns:[],
   trainingData:'',
   blogs:[],
   blogData:'',
   trainers:[],
   trainerData:'',
   headerImages:[],
   trainerDocument:[],
   eventsData:[],
   eventsOneData:''

}
