import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import AboutPage from './pages/AboutPage';
import Header from './components/Header';
import TrainingPage from './pages/TrainingPage';
import SingleTrainingPage from './pages/SingleTrainingPage';
import ContactPage from './pages/ContactPage';
import BlogPage from './pages/BlogPage';
import BlogData from './pages/BlogData';
import Footer from './components/sections/Footer';
import TrainersPage from './pages/TrainersPage';
import SingleTrainerPage from './pages/SingleTrainerPage';
import NotFoundPage from './pages/NotFoundPage';
import Loading from './components/Loading';
import { connect } from 'react-redux';
import EventsPage from './pages/EventsPage';
import SingleEventsPage from './pages/SingleEventsPage';

export class App extends Component {
  render() {
    const {loader} = this.props;
    return (
      <div>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/trainings' element={<TrainingPage />} />
            <Route path='/trainers' element={<TrainersPage />} />
            <Route path='/training/:id' element={<SingleTrainingPage />} />
            <Route path='/trainer/:id' element={<SingleTrainerPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/blog' element={<BlogPage />} />
            <Route path='/events' element={<EventsPage />} />
            <Route path='/blog/:id' element={<BlogData />} />
            <Route path='/events/:id' element={<SingleEventsPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
          <Footer />
          {
            loader?
            <Loading />:null
          }
          
        </BrowserRouter>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  loader: state.Data.loader
})
export default connect(mapStateToProps)(App)