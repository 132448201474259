import React, { Component } from 'react'
import { configRedux } from '../redux/ConfigRedux'
import { getBlogData, insertNewData } from '../actions/MainAction';
import { useParams } from 'react-router-dom';
import PageHeaderSection from '../components/sections/PageHeaderSection';
import CalendarSvg from '../components/svgs/CalendarSvg';
import moment from 'moment';
import EyeSvg from '../components/svgs/EyeSvg';
import FaqSection from '../components/sections/FaqSection';
import { Helmet } from 'react-helmet';
function withHooks(Component){
    return props=> <Component {...props} params={useParams()} />
  }
export class BlogData extends Component {
    componentDidMount(){
        this.props.dispatch(getBlogData(this.props.params.id));
        
        if(localStorage.getItem("nicodeBlogRead")){
          const arr = JSON.parse(localStorage.getItem("nicodeBlogRead"));
          const check = arr.includes(this.props.params.id);
          if(!check){
            this.props.dispatch(insertNewData('public-blog-read',{id:this.props.params.id}));
            arr.push(this.props.params.id);
            localStorage.setItem("nicodeBlogRead", JSON.stringify(arr))
          }
        }else{
          this.props.dispatch(insertNewData('public-blog-read',{id:this.props.params.id}))
          let arr = [this.props.params.id];
          localStorage.setItem("nicodeBlogRead", JSON.stringify(arr))
        }
        window.scrollTo(0, 0);
    }
  render() {
    const {blogData} = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Blog Haqqında - Nicode Academy</title>
          <meta name="description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta name="keywords" content="Nicode Academy, Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI " />
          <link rel="canonical" href="https://nicode.az/blog"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content="Bloqlarımız - Nicode Academy"/>
          <meta property="og:title" content="Bloqlarımız - Nicode Academy"/>
          <meta property="og:url" content="https://nicode.az/blog"/>
          <meta property="og:site_name" content="https://nicode.az/blog"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az"/>
          <meta property="og:title" content="Bloqlarımız - Nicode Academy"/>
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
        </Helmet>
        <PageHeaderSection title={'Bloq yazıları'} />
        <div className='container'>
            <div className='single_blog_img'>
                <img src={blogData?.image_full_url} alt={blogData?.title} />
            </div>
            <div className='mt-3 flex items-center gap-20'>
                <span className='blog_card_date'>
                    <CalendarSvg />
                    {moment(blogData?.created_at).format("MMMM Do YYYY")}
                </span>
                <span style={{ marginTop:0 }} className='blog_card_user'>
                    Admin 
                    <span>
                        <EyeSvg />
                        {blogData?.read_count}
                    </span>
                </span>
            </div>
            <h2 className='blog_data_title'>{blogData?.title}</h2>
            <p className='blog_data_text' dangerouslySetInnerHTML={{ __html: blogData?.description}}></p>
        </div>
        {/* <FaqSection /> */}
      </div>
    )
  }
}

export default configRedux(withHooks(BlogData), ["blogData"])