import React, { Component } from 'react'
import DownloadSvg from '../svgs/DownloadSvg';
import { Link } from 'react-router-dom';

export class TrainingAboutSection extends Component {
  render() {
    const {data} = this.props;

    return (
        <div className="training_details_container">
            {
                data?.trainer?.length == 1?
                <Link to={`/trainer/${data?.trainer[0]?.user?.id}`} className="training_detail_card active_training_box">
                    <div className="training_detail_body">
                        <h3>Təlimçi:</h3>
                        <span >{data?.trainer[0]?.user?.name}</span>
                    </div>
                </Link>:null
            }
            
            <div className="training_detail_card">
                <div className="training_detail_body">
                    <h3>Təlimin müddəti:</h3>
                    <span>{data?.duration}</span>
                </div>
            </div>
            <div className="training_detail_card">
                <div className="training_detail_body">
                    <h3>Təlimin qiyməti:</h3>
                    <span>{data?.price}</span>
                </div>
            </div>
            <div className="training_detail_card">
                <div className="training_detail_body">
                    <h3>Tələbələr üçün:</h3>
                    <span>{data?.student_price}</span>
                </div>
            </div>
            {
                data?.syllabus_full_url && data?.syllabus_full_url != ''?
                <div className="training_detail_card">
                <a target='_blank' href={data?.syllabus_full_url} download={true} className="training_detail_body syllabus_card">
                    <h3 style={{ color: 'black', display: 'inline-flex', alignItems:'center', gap:'10px' }}>Təlim proqramını yüklə</h3>
                    <DownloadSvg /> 
                    {/* <span style={{ display: 'inline-flex', alignItems:'center', gap:'10px', marginTop:'10px' }}><DownloadSvg /> Ətraflı bax</span> */}
                </a>
            </div>:null
            }
            
        </div>
    )
  }
}

export default TrainingAboutSection